import dayjs from 'dayjs'

import { parseDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import { BaserowFieldType, BaserowFormulaType } from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartOrder,
  BarChartSort,
} from '../../lib/types'

import type { ChartItem } from './getChartData'

const compareNumber = (
  aValue: string | number,
  bValue: string | number,
): number => {
  const aNum = Number(aValue)
  const bNum = Number(bValue)
  if (isNaN(aNum) && isNaN(bNum)) return 0
  if (isNaN(aNum)) return 1
  if (isNaN(bNum)) return -1
  return aNum - bNum
}

const valueComparison = (
  aValue: string | number = '',
  bValue: string | number = '',
  field: AnyBaserowField,
): number => {
  const format = `DD/MM/YYYY${field?.date_include_time ? (field?.date_time_format === '24' ? ' HH:mm' : ' hh:mma') : ''}`
  const numberType =
    [
      BaserowFieldType.COUNT,
      BaserowFieldType.AUTONUMBER,
      BaserowFieldType.NUMBER,
      BaserowFieldType.RATING,
      BaserowFieldType.RATING,
    ].includes(field?.type) || field?.formula_type === BaserowFormulaType.NUMBER

  if (field?.type === BaserowFieldType.DURATION) {
    // Sort by duration number
    return parseDurationValue(aValue) - parseDurationValue(bValue)
  } else if (numberType) {
    //Sort by Number
    return compareNumber(aValue, bValue)
  } else if (
    dayjs(aValue, format).isValid() &&
    dayjs(bValue, format).isValid()
  ) {
    // Sort by Date
    const aDate = dayjs(aValue, format)
    const bDate = dayjs(bValue, format)

    if (aDate.isSame(bDate)) return 0
    return aDate.isAfter(bDate) ? 1 : -1
  } else {
    //Sort by String
    return String(aValue).toLowerCase() > String(bValue).toLowerCase() ? 1 : -1
  }
}

export const sortChartData = (
  chartData: ChartItem[],
  sortBy: BarChartSort,
  sortOrder: BarChartOrder,
  matchingField: AnyBaserowField,
  groupByField: AnyBaserowField = null,
): ChartItem[] => {
  // Apply sorting

  // Exit early if possible
  if (!chartData?.length || !sortOrder || sortOrder === 'default') {
    return chartData
  }

  const compareField = sortBy === 'value' ? 'value' : 'name'

  const sortedData = [...chartData].sort((a, b) => {
    if (compareField === 'value') {
      if (groupByField) {
        // Compare grouped records
        return compareNumber(a?.records?.length, b?.records?.length)
      } else {
        // Compare calculated value
        return compareNumber(a?.[compareField], b?.[compareField])
      }
    } else {
      return valueComparison(
        a?.[compareField],
        b?.[compareField],
        groupByField ?? matchingField,
      )
    }
  })

  return sortOrder === 'desc' ? sortedData.reverse() : sortedData
}
