import { useState } from 'react'

import {
  ExclamationTriangleIcon,
  ArrowTopRightOnSquareIcon,
  ArrowTurnDownRightIcon,
} from '@heroicons/react/24/outline'
import { Link, Skeleton, Tooltip } from '@mui/material'
import { captureException } from '@sentry/browser'
import type {
  GetHubDashCardLinkedResources,
  GetHubDashCardLinkedResourcesVariables,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { LinkedResourceIcon } from '../CardSettingsDrawer/LinkLearningContent/LinkLearningContent'
import {
  getContentNiceName,
  type LinkedLearnerTaskDetails,
} from '../CardSettingsDrawer/LinkLearningContent/LinkLearningContentDialog'
import { LinkedResourceType } from '../HubDashLayoutListCell/HubDashLayoutListCell'

export const HUB_DASH_CARD_LINKED_RESOURCES = gql`
  query GetHubDashCardLinkedResources($hubDashLayoutToHubDashCardId: Int!) {
    hubDashCardToHubResourcesByCardId(
      hubDashLayoutToHubDashCardId: $hubDashLayoutToHubDashCardId
    ) {
      id
      resourceId
      resourceType
      resourceName
      resolvedResourceId
      linkMetaData
    }
  }
`

export type LinkedContent =
  GetHubDashCardLinkedResources['hubDashCardToHubResourcesByCardId'][0]

interface LinkedLearningContentListProps {
  cardId: number
}

type BreakdownLinkedResources = {
  linkedProcessMaps: LinkedContent[]
  linkedKnowledgeBaseItems: LinkedContent[]
  linkedLearnerCourses: LinkedContent[]
}

const breakdownLinkedResources = (
  linkedResources: LinkedContent[],
): BreakdownLinkedResources => {
  return linkedResources.reduce<BreakdownLinkedResources>(
    (acc, resource) => {
      switch (resource.resourceType) {
        case LinkedResourceType.PROCESS_MAP:
          acc.linkedProcessMaps.push(resource)
          break
        case LinkedResourceType.KNOWLEDGE_BASE:
          acc.linkedKnowledgeBaseItems.push(resource)
          break
        case LinkedResourceType.LEARNER_COURSE:
          acc.linkedLearnerCourses.push(resource)
          break
      }
      return acc
    },
    {
      linkedProcessMaps: [],
      linkedKnowledgeBaseItems: [],
      linkedLearnerCourses: [],
    },
  )
}

const getResourceLink = (
  resourceId: number,
  resourceType: LinkedResourceType | 'LESSON',
  taskId?: number,
  activityId?: number,
): string => {
  switch (resourceType) {
    case LinkedResourceType.PROCESS_MAP:
      return routes.reactMapById({ id: resourceId })
    case LinkedResourceType.KNOWLEDGE_BASE:
      return routes.knowledgeWithId({ id: resourceId })
    case LinkedResourceType.LEARNER_COURSE:
      return routes.learnerCourse({ id: resourceId })
    case 'LESSON':
      return routes.learnerCourseTask({ taskId, activityId, id: resourceId })
  }
}

const LinkedLesson = ({
  item,
  lesson,
}: {
  item: LinkedContent
  lesson: LinkedLearnerTaskDetails
}) => {
  return (
    <Link
      href={getResourceLink(
        item?.resourceId,
        'LESSON',
        lesson?.taskId,
        lesson?.activityId,
      )}
      target="_blank"
      rel="noreferrer"
      key={lesson?.taskId}
      className="flex w-full items-center justify-between truncate px-4 py-2 pl-6 text-gray-600 no-underline hover:bg-gray-50 hover:text-indigo-600 focus:bg-gray-50 focus:text-indigo-600"
    >
      <span className="flex min-w-[0] items-center gap-2">
        <ArrowTurnDownRightIcon className="h-4 w-4 shrink-0" />
        <span className="max-w-[90%] truncate">{lesson?.taskName}</span>
      </span>
      <ArrowTopRightOnSquareIcon className="h-4 w-4 shrink-0" />
    </Link>
  )
}

const ItemList = ({
  type,
  items,
}: {
  type: LinkedResourceType
  items: LinkedContent[]
}) => {
  return (
    <div className="rounded border">
      <div className="flex items-center justify-start gap-2 border-b bg-gray-100 p-3">
        <div className="rounded-full text-indigo-600">
          <LinkedResourceIcon type={type} />
        </div>
        <p>{`${getContentNiceName(type)}s`}</p>
      </div>
      <div className="flex flex-col">
        {items?.map((item) => {
          const linkedMetaData =
            (item?.linkMetaData as LinkedLearnerTaskDetails[]) ?? []

          return (
            <div key={item?.resourceId + item?.resourceType}>
              {item?.resolvedResourceId && (
                <>
                  <Link
                    href={getResourceLink(item?.resourceId, type)}
                    target="_blank"
                    rel="noreferrer"
                    className="flex w-full items-center justify-between truncate border-t px-4 py-2 text-gray-600 no-underline hover:bg-gray-50 hover:text-indigo-600 focus:bg-gray-50 focus:text-indigo-600"
                  >
                    <span className="max-w-[94%] truncate">
                      {item?.resourceName}
                    </span>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 shrink-0" />
                  </Link>
                  {linkedMetaData?.length > 0 &&
                    type === LinkedResourceType.LEARNER_COURSE &&
                    linkedMetaData?.map((lesson) => (
                      <LinkedLesson
                        key={lesson?.taskId}
                        item={item}
                        lesson={lesson}
                      />
                    ))}
                </>
              )}
              {!item?.resolvedResourceId && (
                <Tooltip
                  arrow
                  title="This resource has been deleted or you do not have access to it. Contact your admin to get access to this resource."
                >
                  <p className="flex w-full items-center justify-between truncate bg-gray-50 px-4 py-2 text-gray-400">
                    <span className="w-[90%] truncate">
                      {item?.resourceName}
                    </span>
                    <ExclamationTriangleIcon className="h-4 w-4 shrink-0 text-amber-600" />
                  </p>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const LinkedLearningContentList = ({
  cardId,
}: LinkedLearningContentListProps) => {
  const [linkedResources, setLinkedResources] = useState<LinkedContent[]>([])

  const { loading: loadingLinkedContent } = useQuery<
    GetHubDashCardLinkedResources,
    GetHubDashCardLinkedResourcesVariables
  >(HUB_DASH_CARD_LINKED_RESOURCES, {
    variables: {
      hubDashLayoutToHubDashCardId: cardId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setLinkedResources(data?.hubDashCardToHubResourcesByCardId)
    },
    onError: (error) => {
      captureException(
        new Error(`Error fetching Linked Hub Resources for Card ${cardId}`),
        {
          extra: {
            error,
          },
        },
      )
    },
  })

  const { linkedProcessMaps, linkedKnowledgeBaseItems, linkedLearnerCourses } =
    breakdownLinkedResources(linkedResources)

  return (
    <div className="mb-20 p-3 px-4">
      {loadingLinkedContent && (
        <div className="flex h-[40vh]">
          <Skeleton
            variant="rounded"
            width="100%"
            height="100%"
            animation="wave"
          />
        </div>
      )}
      {!loadingLinkedContent && (
        <>
          {linkedResources?.length > 0 && (
            <>
              <p className="pt-2 text-sm uppercase text-gray-500">
                Learning Content
              </p>
              <div className="flex flex-col gap-4 py-4">
                {linkedProcessMaps?.length > 0 && (
                  <ItemList
                    type={LinkedResourceType.PROCESS_MAP}
                    items={linkedProcessMaps}
                  />
                )}
                {linkedKnowledgeBaseItems?.length > 0 && (
                  <ItemList
                    type={LinkedResourceType.KNOWLEDGE_BASE}
                    items={linkedKnowledgeBaseItems}
                  />
                )}
                {linkedLearnerCourses?.length > 0 && (
                  <ItemList
                    type={LinkedResourceType.LEARNER_COURSE}
                    items={linkedLearnerCourses}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default LinkedLearningContentList
