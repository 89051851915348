import { Skeleton } from '@mui/material'
import type {
  FindColorModuleQuery,
  FindColorModuleQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import ColorModule from 'src/components/HubDash/CardExpand/InfoPanel/ColorModule'

export const beforeQuery = ({ hubDashCardId }) => {
  return { variables: { hubDashCardId } }
}

export const QUERY = gql`
  query FindColorModuleQuery($hubDashCardId: Int!) {
    colorList: hubDashColorMapsByHubDashCardId(hubDashCardId: $hubDashCardId) {
      id
      name
      description
      colorName
      icon
    }
  }
`

type CellProps = CellSuccessProps<
  FindColorModuleQuery,
  FindColorModuleQueryVariables
>

export type RecordColor = CellProps['colorList'][0]

export const Loading = () => (
  <div className="flex h-[40vh] flex-col gap-2 p-4">
    <Skeleton variant="rounded" width="100%" height="100%" animation="wave" />
  </div>
)

export const Empty = ({
  selectedColor,
  setSelectedColor,
  view,
  records,
  card,
}) => {
  return (
    <ColorModule
      colorList={[]}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      view={view}
      records={records}
      card={card}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  colorList,
  selectedColor,
  setSelectedColor,
  view,
  records,
  card,
}) => {
  return (
    <ColorModule
      colorList={colorList}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      view={view}
      records={records}
      card={card}
    />
  )
}
