// Get the "nice" display values for a Baserow field in an Array

import dayjs from 'dayjs'

import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import type {
  BaserowFormulaField,
  BaserowLookupField,
} from '../../lib/baserow/baserowFieldTypes'
import { BaserowFieldType, BaserowFormulaType } from '../../lib/enums'
import type { AnyBaserowField } from '../../lib/types'

import { getFormulaFieldDisplayValues } from './getFormulaFieldDisplayValues'
import { getLookupFieldDisplayValues } from './getLookupFieldDisplayValues'

const capitalizeFirstLetter = (value: any): string =>
  String(value).charAt(0).toUpperCase() + String(value).slice(1)

const isValidNumber = (value: any): boolean =>
  value !== null && (Number(value) === 0 || Boolean(Number(value)))

// Lets us easily work with array_type returns on Baserow Fields
export const getFieldDisplayValues = (
  currentValue: any, // not typed yet
  displayField: AnyBaserowField,
): string[] => {
  switch (displayField?.type) {
    case BaserowFieldType.CREATED_ON:
    case BaserowFieldType.DATE:
    case BaserowFieldType.LAST_MODIFIED: {
      if (currentValue && dayjs(currentValue).isValid()) {
        return [
          dayjs(currentValue).format(
            `DD/MM/YYYY${displayField?.date_include_time ? (displayField?.date_time_format === '24' ? ' HH:mm' : ' hh:mma') : ''}`,
          ),
        ]
      }
      return ['']
    }

    case BaserowFieldType.SINGLE_SELECT: {
      if (currentValue?.value) {
        return [currentValue?.value]
      }
      return ['']
    }
    case BaserowFieldType.MULTIPLE_SELECT: {
      if (currentValue?.length > 0) {
        return currentValue?.map((option) => option?.value)
      }
      return ['']
    }

    case BaserowFieldType.LOOKUP: {
      if (currentValue?.length > 0) {
        return getLookupFieldDisplayValues(
          currentValue,
          displayField?.array_formula_type,
          displayField as BaserowLookupField,
        )
      }
      return ['']
    }
    case BaserowFieldType.FORMULA: {
      if (Array.isArray(currentValue) && currentValue?.length === 0) {
        return ['']
      }

      return getFormulaFieldDisplayValues(
        currentValue,
        displayField?.formula_type,
        displayField as BaserowFormulaField,
      )
    }
    case BaserowFieldType.LINK_ROW: {
      if (currentValue?.length > 0) {
        return currentValue?.map((row) => row?.value ?? '')
      }
      return ['']
    }
    case BaserowFieldType.UUID:
    case BaserowFieldType.LONG_TEXT:
    case BaserowFieldType.EMAIL:
    case BaserowFieldType.URL:
    case BaserowFieldType.TEXT:
    case BaserowFieldType.PHONE_NUMBER: {
      if (currentValue) {
        return [currentValue]
      }
      return ['']
    }
    case BaserowFieldType.FILE: {
      if (currentValue?.length > 0) {
        return currentValue?.map((file) => file?.visible_name)
      }
      return ['']
    }
    case BaserowFieldType.DURATION: {
      if (currentValue || currentValue === 0) {
        return [formatDurationValue(currentValue, displayField.duration_format)]
      }
      return ['']
    }
    case BaserowFieldType.BOOLEAN: {
      return [capitalizeFirstLetter(currentValue)]
    }
    case BaserowFieldType.MULTIPLE_COLLABORATORS: {
      if (currentValue?.length > 0) {
        return currentValue?.map((user) => user?.name)
      }
      return ['']
    }
    case BaserowFieldType.CREATED_BY:
    case BaserowFieldType.LAST_MODIFIED_BY: {
      if (currentValue) {
        return [currentValue?.name ?? '']
      }
      return ['']
    }
    case BaserowFieldType.COUNT:
    case BaserowFieldType.AUTONUMBER:
    case BaserowFieldType.NUMBER:
    case BaserowFieldType.RATING: {
      if (isValidNumber(currentValue)) {
        return [String(currentValue)]
      }
      return ['']
    }
    case BaserowFieldType.ROLLUP: {
      if (currentValue) {
        if (displayField?.formula_type === BaserowFormulaType.NUMBER) {
          return [String(currentValue)]
        } else if (displayField?.formula_type === BaserowFormulaType.BOOLEAN) {
          return [capitalizeFirstLetter(currentValue)]
        }
      }
      return ['']
    }
    // Field Types not supported
    case BaserowFieldType.AI:
    case BaserowFieldType.PASSWORD:
      return ['']
    default:
      return ['']
  }
}
