import HubDash from 'src/components/HubDash/HubDash'
import Spinner from 'src/components/Library/Loading'
import useHubDashStore from 'src/lib/stores/hubDashStore'

export const QUERY = gql`
  query LoginToBaserowQuery {
    loginToBaserow {
      jwt
    }
  }
`

export const Loading = () => <Spinner />

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }) => {
  return <div style={{ color: 'red' }}>Error: {error?.message}</div>
}

export const Success = ({ loginToBaserow }) => {
  useHubDashStore.setState({ token: loginToBaserow.jwt })
  return <HubDash />
}
