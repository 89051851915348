import type { FC } from 'react'

import { trimToCharLength } from 'src/Util'

import type { GroupOption } from './ChartFunctions/getChartData'

interface ChartTooltipProps {
  payload?: any[]
  active?: boolean
  isGrouped?: boolean
  groupOptions?: GroupOption[]
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  payload = [],
  active = false,
  isGrouped = false,
  groupOptions = [],
}) => {
  const tooltipPayload = payload?.[0]?.payload ?? {
    name: 'Undefined',
    value: 0,
  }

  if (active && tooltipPayload) {
    return (
      <div className="max-h-[400px] max-w-[400px] overflow-hidden border bg-white p-3">
        <p className="label">{`${trimToCharLength(tooltipPayload?.name, 25)} : ${isGrouped ? tooltipPayload?.records?.length : tooltipPayload?.value}`}</p>
        {isGrouped &&
          groupOptions?.map((option) => {
            return tooltipPayload[option?.name] ? (
              <p
                key={option?.name}
                className="label mt-1 flex items-center gap-1 text-sm text-gray-500"
              >
                <div
                  className="h-4 w-4 min-w-4 flex-shrink-0 rounded-full"
                  style={{ backgroundColor: option?.hex }}
                ></div>
                {tooltipPayload[option?.name] &&
                  `${trimToCharLength(option?.name, 25)} : ${tooltipPayload[option?.name]}`}
              </p>
            ) : null
          })}
      </div>
    )
  }

  return null
}

export default ChartTooltip
