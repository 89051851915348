import { useEffect, useRef, useState, type FC } from 'react'

import { Typography } from '@mui/material'
import { calculatePercentageOf } from 'api/src/common/utils'
import dayjs from 'dayjs'
import { clamp, median, sum } from 'ramda'

import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import { DisplayUnits } from '../CardSettingsDrawer/CardSettingsDrawer'
import { SummaryType } from '../CardSettingsDrawer/FieldSummarySettings'
import { BaserowFieldType, BaserowFormulaType } from '../lib/enums'
import type {
  AnyBaserowField,
  HubDashCardFilters,
  HubDashCardType,
} from '../lib/types'

const collapseRowValues = (values: any[], field: AnyBaserowField): any[] => {
  const collapsed = values?.map((value) => {
    if (Array.isArray(value)) {
      return breakdownArrayFormulaValue(value, field?.array_formula_type)
    } else {
      if (
        [
          BaserowFieldType.COUNT,
          BaserowFieldType.AUTONUMBER,
          BaserowFieldType.NUMBER,
          BaserowFieldType.RATING,
        ].includes(field?.type)
      ) {
        return Number(value)
      }
      return value
    }
  })
  return collapsed.flat()
}

const breakdownArrayFormulaValue = (
  value: any[] = [],
  fieldType: BaserowFormulaType,
): any[] => {
  switch (fieldType) {
    case BaserowFormulaType.DATE:
    case BaserowFormulaType.DATE_INTERVAL:
      return value?.length === 0
        ? [null]
        : value.map((value) => value.value).flat()
    case BaserowFormulaType.NUMBER:
    case BaserowFormulaType.DURATION:
      return value?.length === 0
        ? [null]
        : value.map((value) => Number(value.value)).flat()
    case BaserowFormulaType.ARRAY:
    case BaserowFormulaType.BOOLEAN:
    case BaserowFormulaType.BUTTON:
    case BaserowFormulaType.CHAR:
    case BaserowFormulaType.INVALID:
    case BaserowFormulaType.LINK:
    case BaserowFormulaType.MULTIPLE_SELECT:
    case BaserowFormulaType.SINGLE_FILE:
    case BaserowFormulaType.SINGLE_SELECT:
    case BaserowFormulaType.TEXT:
    case BaserowFormulaType.URL:
      // Options are not available to reach here yet
      // Pass through NULL until scope what happens to these
      return [null]
  }
}

const getRowValues = (records: any[], matchedField: AnyBaserowField): any[] => {
  const values = records?.map((row) => {
    // Return value if matched field
    if (matchedField) {
      const currentValue = row?.getCellValue(matchedField?.name)

      switch (matchedField?.type) {
        // Array Values - Break down to a single value
        case BaserowFieldType.FILE:
          return currentValue?.length === 0
            ? null
            : currentValue?.map((value) => value?.visible_name)
        case BaserowFieldType.LINK_ROW:
        case BaserowFieldType.MULTIPLE_COLLABORATORS:
          return currentValue?.length === 0
            ? null
            : currentValue?.map((value) => value?.id)
        case BaserowFieldType.MULTIPLE_SELECT:
          return currentValue?.length === 0
            ? null
            : currentValue?.map((value) => value?.value)
        case BaserowFieldType.LOOKUP:
          return currentValue?.length === 0 ? null : currentValue
        // Nullable Values
        case BaserowFieldType.AUTONUMBER:
        case BaserowFieldType.UUID:
        case BaserowFieldType.ROLLUP:
        case BaserowFieldType.COUNT:
        case BaserowFieldType.FORMULA:
        case BaserowFieldType.PHONE_NUMBER:
        case BaserowFieldType.SINGLE_SELECT:
        case BaserowFieldType.EMAIL:
        case BaserowFieldType.URL:
        case BaserowFieldType.DURATION:
        case BaserowFieldType.CREATED_BY:
        case BaserowFieldType.CREATED_ON:
        case BaserowFieldType.LAST_MODIFIED:
        case BaserowFieldType.LAST_MODIFIED_BY:
        case BaserowFieldType.DATE:
        case BaserowFieldType.BOOLEAN:
        case BaserowFieldType.RATING:
        case BaserowFieldType.NUMBER:
        case BaserowFieldType.LONG_TEXT:
        case BaserowFieldType.TEXT:
          return currentValue || null
        // Field Types not yet implemented
        case BaserowFieldType.AI:
        case BaserowFieldType.PASSWORD:
          return currentValue || null
        default:
          return null
      }
    }
    return null
  })

  return values
}

const getCounterDisplayString = (
  counterNumber: number,
  counterString: string,
  displayUnits: DisplayUnits,
  hideUnits: boolean,
): string => {
  let returnString = '-'

  if (counterString) {
    returnString = counterString
  } else {
    returnString = `${String(counterNumber)}`
  }

  if (displayUnits === DisplayUnits.CURRENCY && !hideUnits) {
    returnString = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }).format(parseFloat(returnString))
  }

  if (displayUnits === DisplayUnits.PERCENT && !hideUnits) {
    returnString = `${returnString}%`
  }

  return returnString
}

enum DataStatus {
  NO_DATA = 'No Data',
}

interface CardContentCounterProps {
  cardSocket: any // not typed
  card: HubDashCardType
  triggerResize: number
}

const CardContentCounter: FC<CardContentCounterProps> = ({
  cardSocket,
  card,
  triggerResize,
}) => {
  // Primary display figure
  let counterNumber = 0

  // Used for extra symbols (%) or fallback for invalid values/types
  let counterString = null

  let showPercent = false

  const MAX_DECIMAL_COUNT = 3

  const cardSettingsFilters = card?.cardSettings?.filters as HubDashCardFilters
  const countType = cardSettingsFilters?.counter?.count ?? 'colors'
  const summaryType = cardSettingsFilters?.counter?.summaryType ?? ''
  const displayUnits =
    cardSettingsFilters?.counter?.displayUnits ?? DisplayUnits.NONE

  // Find the field by saved ID
  const matchedField =
    cardSocket?.table?.fields?.find(
      (field) => field?.id === cardSettingsFilters?.counter?.field?.id,
    ) ?? null

  const isDurationFormat =
    matchedField?.type === BaserowFieldType.DURATION ||
    matchedField?.formula_type === BaserowFormulaType.DURATION ||
    matchedField?.array_formula_type === BaserowFormulaType.DURATION

  if (countType === 'colors') {
    let totalCount = 0
    for (const record of cardSocket.activeRecords) {
      totalCount += record?.decorators?.left_border_color.length
    }
    counterNumber = totalCount
  } else if (countType === 'records') {
    counterNumber = cardSocket?.records?.length
  } else if (countType === 'field') {
    // Get all row values - flatMap for array results
    const values = getRowValues(cardSocket?.records, matchedField)

    const numberTypeFields = [
      BaserowFieldType.NUMBER,
      BaserowFieldType.AUTONUMBER,
      BaserowFieldType.COUNT,
      BaserowFieldType.DURATION,
    ]

    const dateTypeFields = [
      BaserowFieldType.LAST_MODIFIED,
      BaserowFieldType.DATE,
      BaserowFieldType.CREATED_ON,
    ]

    // Get the up to date field type
    const matchedFieldType = matchedField?.type ?? BaserowFieldType.TEXT

    const formulaNumberFields = [
      BaserowFormulaType.NUMBER,
      BaserowFormulaType.DURATION,
    ]
    const formulaDateFields = [
      BaserowFormulaType.DATE,
      BaserowFormulaType.DATE_INTERVAL,
    ]

    // IF the formula type matches a number type - calc results for number values
    if (
      formulaNumberFields.includes(matchedField?.formula_type) ||
      formulaNumberFields.includes(matchedField?.array_formula_type)
    ) {
      numberTypeFields.push(matchedField?.type as BaserowFieldType)
    }

    // IF the formula type matches a date type - calc results for date values
    if (
      formulaDateFields.includes(matchedField?.formula_type) ||
      formulaDateFields.includes(matchedField?.array_formula_type)
    ) {
      dateTypeFields.push(matchedField?.type as BaserowFieldType)
    }

    switch (summaryType) {
      case SummaryType.EMPTY:
        {
          const availableValues = values?.filter((value) => !value)
          if (availableValues?.length > 0) {
            counterNumber = availableValues?.length
          } else {
            counterNumber = 0
          }
        }
        break
      case SummaryType.FILLED:
        {
          const availableValues = values?.filter((value) => Boolean(value))
          if (availableValues?.length > 0) {
            counterNumber = availableValues?.length
          } else {
            counterNumber = 0
          }
        }
        break
      case SummaryType.PERCENT_EMPTY:
        {
          const availableValues = values?.filter((value) => !value)

          if (availableValues?.length > 0) {
            const count = availableValues?.length
            counterNumber = calculatePercentageOf(
              0,
              count,
              cardSocket?.records?.length,
            )
            showPercent = true
          } else {
            counterNumber = 0
            showPercent = true
          }
        }
        break
      case SummaryType.PERCENT_FILLED:
        {
          const availableValues = values?.filter((value) => Boolean(value))

          if (availableValues?.length > 0) {
            const count = availableValues?.length
            counterNumber = calculatePercentageOf(
              0,
              count,
              cardSocket?.records?.length,
            )
            showPercent = true
          } else {
            counterNumber = 0
            showPercent = true
          }
        }
        break
      case SummaryType.EARLIEST_DATE: {
        // Get Values and sort by earliest
        const collapsedValues = collapseRowValues(values, matchedField)
          ?.filter((value) => Boolean(value))
          .sort((a, b) => {
            return a > b ? 1 : -1
          })

        // Check if DATE type and we have a value that is valid
        if (
          dateTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          if (dayjs(collapsedValues[0]).isValid()) {
            counterString = dayjs(collapsedValues[0])?.format('DD/MM/YYYY')
          } else {
            counterString = '-'
          }
        } else {
          counterString = DataStatus.NO_DATA
        }

        break
      }
      case SummaryType.LATEST_DATE: {
        // Get Values and sort by latest

        const collapsedValues = collapseRowValues(values, matchedField)
          ?.filter((value) => Boolean(value))
          ?.sort((a, b) => {
            return a < b ? 1 : -1
          })
        // Check if DATE type and we have a value that is valid
        if (
          dateTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          if (dayjs(collapsedValues[0]).isValid()) {
            counterString = dayjs(collapsedValues[0])?.format('DD/MM/YYYY')
          } else {
            counterString = '-'
          }
        } else {
          counterString = DataStatus.NO_DATA
        }

        break
      }
      case SummaryType.SUM: {
        const availableValues = values?.filter((value) => Boolean(value))
        const collapsedValues = collapseRowValues(availableValues, matchedField)

        if (
          numberTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          const value = Number(
            sum(collapsedValues).toFixed(
              clamp(0, MAX_DECIMAL_COUNT, matchedField?.number_decimal_places),
            ),
          )
          if (isDurationFormat) {
            counterString = formatDurationValue(
              value,
              matchedField?.duration_format,
            )
          } else {
            counterNumber = value
          }
        } else {
          counterString = DataStatus.NO_DATA
        }
        break
      }
      case SummaryType.AVERAGE: {
        const availableValues = values?.filter((value) => Boolean(value))
        const collapsedValues = collapseRowValues(availableValues, matchedField)
        if (
          numberTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          const value = Number(
            (sum(collapsedValues) / collapsedValues?.length).toFixed(
              clamp(0, MAX_DECIMAL_COUNT, matchedField?.number_decimal_places),
            ),
          )
          if (isDurationFormat) {
            counterString = formatDurationValue(
              value,
              matchedField?.duration_format,
            )
          } else {
            counterNumber = value
          }
        } else {
          counterString = DataStatus.NO_DATA
        }
        break
      }
      case SummaryType.MIN: {
        const availableValues = values?.filter((value) => Boolean(value))

        const collapsedValues = collapseRowValues(
          availableValues,
          matchedField,
        )?.sort((a, b) => a - b)

        if (
          numberTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          const value = Number(
            Number(collapsedValues[0]).toFixed(
              clamp(0, MAX_DECIMAL_COUNT, matchedField?.number_decimal_places),
            ),
          )

          if (isDurationFormat) {
            counterString = formatDurationValue(
              value,
              matchedField?.duration_format,
            )
          } else {
            counterNumber = value
          }
        } else {
          counterString = DataStatus.NO_DATA
        }
        break
      }
      case SummaryType.MAX: {
        const availableValues = values?.filter((value) => Boolean(value))

        const collapsedValues = collapseRowValues(
          availableValues,
          matchedField,
        )?.sort((a, b) => b - a)

        if (
          numberTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          const value = Number(
            Number(collapsedValues[0]).toFixed(
              clamp(0, MAX_DECIMAL_COUNT, matchedField?.number_decimal_places),
            ),
          )

          if (isDurationFormat) {
            counterString = formatDurationValue(
              value,
              matchedField?.duration_format,
            )
          } else {
            counterNumber = value
          }
        } else {
          counterString = DataStatus.NO_DATA
        }
        break
      }
      case SummaryType.MEDIAN: {
        const availableValues = values?.filter((value) => Boolean(value))

        const collapsedValues = collapseRowValues(
          availableValues,
          matchedField,
        )?.sort((a, b) => (a > b ? 1 : 0))

        if (
          numberTypeFields.includes(matchedFieldType) &&
          collapsedValues?.length > 0
        ) {
          const value = Number(
            median(collapsedValues).toFixed(
              clamp(0, MAX_DECIMAL_COUNT, matchedField?.number_decimal_places),
            ),
          )

          if (isDurationFormat) {
            counterString = formatDurationValue(
              value,
              matchedField?.duration_format,
            )
          } else {
            counterNumber = value
          }
        } else {
          counterString = DataStatus.NO_DATA
        }
        break
      }
      default:
        counterString = '-'
    }
  }

  let textColor = card?.cardSettings?.appearance?.textColor
  if (!textColor) {
    textColor = { name: 'dynamic', hex: '' }
  }

  const getFontSize = (text: string, currentWidth: number): string => {
    if (text === DataStatus.NO_DATA) {
      return '1'
    }

    const approxLetterWidth = 10 // 10px

    // Work out how many times the thing fits in the thing
    const textWidth = approxLetterWidth * text?.length

    // Clamp to nice values
    const multiplier = clamp(1, 5, currentWidth / textWidth)

    return String(multiplier)
  }

  const ref = useRef(null)
  const [fontSize, setSize] = useState('1')

  const summaryTypeDoesNotNeedCustomSymbol =
    summaryType === SummaryType.EMPTY ||
    summaryType === SummaryType.FILLED ||
    summaryType === SummaryType.PERCENT_EMPTY ||
    summaryType === SummaryType.PERCENT_FILLED ||
    summaryType === SummaryType.EARLIEST_DATE ||
    summaryType === SummaryType.LATEST_DATE

  const noDataFound =
    counterString === DataStatus.NO_DATA || counterString === '-'

  const hideDisplayUnits =
    summaryTypeDoesNotNeedCustomSymbol ||
    noDataFound ||
    isDurationFormat ||
    countType !== 'field'

  const displayString = getCounterDisplayString(
    counterNumber,
    counterString,
    displayUnits as DisplayUnits,
    hideDisplayUnits,
  )

  useEffect(() => {
    setSize(getFontSize(displayString, ref.current.offsetWidth))
  }, [ref, triggerResize, counterNumber, counterString, displayUnits])

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center overflow-clip"
      ref={ref}
    >
      <Typography
        variant="h1"
        className={`${textColor.name === 'dynamic' ? (counterNumber === 0 ? 'text-blue-400' : 'text-red-400') : ''} ${noDataFound && 'font-medium text-gray-500'} text-wrap text-center`}
        sx={{
          fontSize: fontSize + 'em',
          fontWeight: 'bold',
        }}
        style={{
          color: textColor.name !== 'dynamic' ? textColor.hex : '',
        }}
        data-testid={`card-content-counter-${card.id}-${counterNumber}`}
      >
        {displayString}
        {showPercent && '%'}
      </Typography>
    </div>
  )
}

export default CardContentCounter
