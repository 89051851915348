import type { Dispatch, FC, SetStateAction } from 'react'

import { Tooltip } from '@mui/material'

import {
  baserowColors,
  type BaserowColor,
} from 'src/components/HubDash/lib/baserow/baserowColors'

interface ColorGridProps {
  selectedColor: BaserowColor
  setSelectedColor: Dispatch<SetStateAction<BaserowColor>>
}

const ColorGrid: FC<ColorGridProps> = ({ selectedColor, setSelectedColor }) => {
  const appearanceColors: BaserowColor[] = [
    { name: 'white', hex: '#ffffff' },
    { name: 'black', hex: '#000000' },
    { name: 'standard', hex: '#a0aec0' },
    { name: 'dynamic', hex: '#000000' },
  ]

  return (
    <div className="grid gap-2 grid-rows-4 grid-cols-11 grid-flow-col">
      {appearanceColors.map((color) => (
        <Tooltip
          title={color.name}
          key={color.name}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
        >
          <button
            key={color.name}
            className={`rounded-lg w-8 h-8 cursor-pointer ${
              color.name === selectedColor.name
                ? 'border-4 border-indigo-500'
                : ''
            }`}
            style={{
              background:
                color.name === 'dynamic'
                  ? 'linear-gradient(135deg, blue 50%, red 50%)'
                  : color.hex,
            }}
            onClick={() => setSelectedColor(color)}
          />
        </Tooltip>
      ))}
      {baserowColors.map((color) => (
        <Tooltip
          title={color.name}
          key={color.name}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
        >
          <button
            key={color.name}
            className={`rounded-lg w-8 h-8 cursor-pointer ${
              color.name === selectedColor.name
                ? 'border-4 border-indigo-500'
                : ''
            }`}
            style={{ backgroundColor: color.hex }}
            onClick={() => setSelectedColor(color)}
          />
        </Tooltip>
      ))}
    </div>
  )
}

export default ColorGrid
