import type { FC } from 'react'

import { trimToCharLength } from 'src/Util'

import type { ChartItem, GroupOption } from './ChartFunctions/getChartData'

interface ChartLegendProps {
  data?: ChartItem[]
  isHorizontal?: boolean
  isGrouped?: boolean
  groupOptions?: GroupOption[]
  textColor?: string
}

const LegendItem = ({
  index,
  hex,
  name,
  color,
}: {
  index: number
  hex: string
  name: string
  color: string
}) => {
  return (
    <div
      key={`legend-item-${index}`}
      className="flex w-[250px] items-center gap-1"
    >
      <div
        className="h-4 w-4 min-w-4 flex-shrink-0 rounded-full"
        style={{ backgroundColor: hex }}
      ></div>
      <div className="flex-shrink-1">
        <p className="truncate text-sm" style={{ color }}>
          {trimToCharLength(name, 25)}
        </p>
      </div>
    </div>
  )
}

const ChartLegend: FC<ChartLegendProps> = ({
  data = [],
  isHorizontal = false,
  isGrouped = false,
  groupOptions = [],
  textColor = '#737373',
}) => {
  return (
    <div
      className={`flex h-full gap-1 overflow-scroll ${isHorizontal ? 'flex-row flex-wrap justify-start' : 'h-full flex-col'} `}
    >
      {isGrouped &&
        groupOptions.map((entry, index) => (
          <LegendItem
            key={index}
            index={index}
            hex={entry?.hex}
            name={entry?.name ?? 'Unnamed'}
            color={textColor}
          />
        ))}
      {!isGrouped &&
        data.map((entry, index) => (
          <LegendItem
            key={index}
            index={index}
            hex={entry?.hex}
            name={entry?.name ?? 'Unnamed'}
            color={textColor}
          />
        ))}
    </div>
  )
}

export default ChartLegend
