import dayjs from 'dayjs'

import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import type { BaserowLookupField } from '../../lib/baserow/baserowFieldTypes'
import { BaserowFormulaType } from '../../lib/enums'

export const getLookupFieldDisplayValues = (
  currentValue: any[], // not typed yet
  formulaType: string,
  displayField: BaserowLookupField,
): string[] => {
  // Exit early for empty fields
  if (currentValue?.length === 0) {
    return ['']
  }

  switch (formulaType) {
    case BaserowFormulaType.ARRAY: {
      return ['']
    }
    case BaserowFormulaType.BOOLEAN: {
      return currentValue?.map((bool) => {
        return (
          String(bool?.value).charAt(0).toUpperCase() +
          String(bool?.value).slice(1)
        )
      })
    }
    case BaserowFormulaType.BUTTON: {
      return currentValue?.map((button) => {
        return button?.value?.url
      })
    }
    case BaserowFormulaType.DATE:
    case BaserowFormulaType.DATE_INTERVAL: {
      return currentValue?.map((date) => {
        if (dayjs(date?.value).isValid()) {
          return dayjs(date?.value).format(
            `DD/MM/YYYY${displayField?.date_include_time ? (displayField?.date_time_format === '24' ? ' HH:mm' : ' hh:mma') : ''}`,
          )
        } else {
          return ''
        }
      })
    }
    case BaserowFormulaType.DURATION: {
      return currentValue?.map((duration) => {
        return formatDurationValue(
          duration?.value,
          displayField.duration_format,
        )
      })
    }
    case BaserowFormulaType.SINGLE_FILE: {
      return currentValue?.map((file) => {
        return file?.visible_name
      })
    }
    case BaserowFormulaType.SINGLE_SELECT: {
      return currentValue?.map((option) => {
        return option?.value?.value
      })
    }
    case BaserowFormulaType.MULTIPLE_SELECT: {
      return currentValue?.flatMap((option) => {
        return option?.value?.map((valueOption) => valueOption?.value)
      })
    }
    case BaserowFormulaType.LINK: {
      return currentValue?.map((link) => {
        return link?.value?.url
      })
    }
    case BaserowFormulaType.NUMBER:
    case BaserowFormulaType.CHAR:
    case BaserowFormulaType.TEXT:
    case BaserowFormulaType.URL: {
      return currentValue?.map((input) => {
        return input?.value ?? 'Blank Value'
      })
    }
    case BaserowFormulaType.INVALID:
    default:
      return ['']
  }
}
