import { isBucketField } from '../../CardSettingsDrawer/ChartSettings/BarChartDataSettings'
import { baserowColors, getHexForColor } from '../../lib/baserow/baserowColors'
import { CHART_EMPTY_COLOR } from '../../lib/constants'
import {
  BaserowFieldType,
  CardType,
  type BucketByValues,
} from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartOrder,
  BarChartSort,
} from '../../lib/types'

import { bucketChartData } from './bucketChartData'
import { getFieldDisplayValues } from './getFieldDisplayValues'
import { sortChartData } from './sortChartData'

type ChartDataItem = {
  name: string
  value: number
  hex: string
  color: string
  records: any[] // not typed yet
}

// Custom type with Specified keys used for ReCharts
// groupByDataKey is for Grouped Charts that require specific key and value for each stack on a bar in the chart
// i.e.  { name: 'Staff Member', "Team 1": 1, "Team 2": 0, "Team 3": 1, }
export type ChartItem = ChartDataItem & {
  [groupByDataKey: string]: number | string | any[]
}

export type GroupOption = {
  name: string
  hex: string
}

export type ChartSortSettings = {
  sortBy: BarChartSort
  sortOrder: BarChartOrder
}

const reservedChartItemKeys = ['name', 'value', 'hex', 'color', 'records']

// Select Fields require predetermined options
const SELECT_FIELDS = [
  BaserowFieldType.SINGLE_SELECT,
  BaserowFieldType.MULTIPLE_SELECT,
]

// Get Chart Data = used for Pie and Bar Charts in HubDash
export const getChartData = (
  dataField: AnyBaserowField,
  records: any[], // not typed yet
  chartType: CardType,
  countRecords: boolean = true,
  includeEmptyRecords: boolean = false,
  bucketValuesBy: BucketByValues = null,
  sortSettings: ChartSortSettings = null,
  groupByField: AnyBaserowField = null,
): { chartDataItems: ChartItem[]; groupByOptions: GroupOption[] } => {
  let chartDataItems: ChartItem[] = []
  const groupByOptions: GroupOption[] = []

  // Step 1:
  // Gather the options
  const fieldUsesSelectOptions = SELECT_FIELDS.includes(dataField?.type)

  // Only set option list if counting records
  if (fieldUsesSelectOptions && countRecords) {
    chartDataItems = dataField?.select_options?.map((option) => {
      return {
        name: option?.value ?? '',
        value: 0,
        color: option?.color ?? '',
        hex: option?.hex ?? '',
      } as ChartDataItem
    })
  }
  let colorIndex = 0

  for (const record of records) {
    const stringValues = getFieldDisplayValues(
      record?.getCellValue(dataField?.name),
      dataField,
    )

    for (const stringValue of stringValues) {
      // Check if a select option matches the color
      const matchingSelectOption =
        dataField?.select_options?.find(
          (option) => option?.value === stringValue,
        ) ?? null

      // Determine Color
      const color =
        fieldUsesSelectOptions && matchingSelectOption
          ? matchingSelectOption?.color
          : baserowColors[colorIndex % baserowColors.length].name

      // Check if we are counting values or using distinct rows
      if (countRecords) {
        // Count the Records - only push one for value
        if (!chartDataItems?.find((option) => option.name === stringValue)) {
          // If not already found - add new option to chart
          chartDataItems.push({
            name: stringValue,
            value: 1,
            color,
            hex: '',
            records: [],
          })
          colorIndex++
        }
      } else {
        // Distinct Records - push for each value
        chartDataItems.push({
          name: stringValue,
          value: 1,
          color,
          hex: '',
          records: [],
        })
        colorIndex++
      }
    }

    // Step 1.5
    // Find the GroupBy Options => Bar Chart Only
    if (groupByField?.id && chartType === CardType.CHART_BAR) {
      const groupByValues = getFieldDisplayValues(
        record?.getCellValue(groupByField?.name),
        groupByField,
      )

      for (const groupByValue of groupByValues) {
        if (
          groupByValue &&
          !groupByOptions?.find((option) => option?.name === groupByValue)
        ) {
          const groupColour =
            groupByField?.select_options?.find(
              (option) => option?.value === groupByValue,
            )?.color ??
            baserowColors[(colorIndex + 0) % baserowColors.length]?.name

          // If not already found - add new option to chart
          groupByOptions.push({
            name: groupByValue,
            hex: getHexForColor(groupColour),
          })

          colorIndex++
        } else {
          // Check if we want empty vals
          if (
            includeEmptyRecords &&
            !groupByOptions?.find((option) => option?.name === 'Empty')
          ) {
            groupByOptions.push({
              name: 'Empty',
              hex: getHexForColor(CHART_EMPTY_COLOR),
            })
            colorIndex++
          }
        }
      }
    }
  }

  // Step 2:
  // Breakdown data to get chart item values
  for (const option of chartDataItems) {
    // Find all records with matching values

    const matchingResults = []

    const optionRecords = records.filter((record) => {
      const stringValues = getFieldDisplayValues(
        record?.getCellValue(dataField?.name),
        dataField,
      )

      // Count how many times the value appears
      const filteredResults = stringValues?.filter(
        (value) => value === option?.name,
      )

      // If the result was found in result => count value
      for (const match of filteredResults) {
        matchingResults.push(match)
      }

      // Return row if record appeared
      return stringValues?.includes(option.name)
    })

    // Assign records to ChartItem
    option.records = optionRecords

    // Update value to count of matching values
    option.value = countRecords ? matchingResults.length : option.value

    // Get the appropriate hex color
    option.hex = getHexForColor(option.color)

    // Step 2.5
    // Assign the values for GroupBy => Bar Chart Only
    if (
      groupByField?.id &&
      chartType === CardType.CHART_BAR &&
      groupByOptions?.length > 0
    ) {
      // Count option values
      let totalOptionCount = 0

      // Loop through the groupByOptions and set them to the ChartItem
      for (const groupByOption of groupByOptions) {
        // Check for a "safe" key
        if (!reservedChartItemKeys?.includes(groupByOption?.name)) {
          const matchedValues = []
          const emptyValues = []

          // Go through each of the attached records and set a groupBy Value
          for (const record of optionRecords) {
            const recordGroupByValues = getFieldDisplayValues(
              record?.getCellValue(groupByField?.name),
              groupByField,
            )
            for (const value of recordGroupByValues) {
              if (value && value === groupByOption?.name) {
                matchedValues?.push(value)
              } else if (!value) {
                emptyValues.push(value)
              }
            }
          }

          option[groupByOption?.name] = matchedValues?.length

          if (groupByOption?.name === 'Empty') {
            option['Empty'] = emptyValues?.length
          }
          // Update total count
          totalOptionCount += matchedValues?.length
        }
      }

      // Update values to match the count of each group
      option.value =
        totalOptionCount +
        Number(includeEmptyRecords ? (option['Empty'] ?? 0) : 0)
    }
  }

  // Step 3:
  // Filter Empty Records
  if (!includeEmptyRecords) {
    // Remove Empty Records
    chartDataItems = chartDataItems?.filter((item) => item?.name !== '')
  } else {
    // Update Empty Records with new label and color
    chartDataItems = chartDataItems?.map((item) => {
      return {
        ...item,
        name: item?.name || 'Empty',
        hex: item?.name ? item?.hex : getHexForColor(CHART_EMPTY_COLOR),
      }
    })
  }

  // Step 3.5:
  // Determined Chart Options from Single/Multiple Select with 0 results get filtered out
  if (fieldUsesSelectOptions) {
    chartDataItems = chartDataItems?.filter((chartItem) => chartItem?.value > 0)
  }

  // Step 4:
  // Bucket Values by type - currently only for Bar Charts
  if (
    isBucketField(dataField) &&
    chartType === CardType.CHART_BAR &&
    !!bucketValuesBy
  ) {
    const bucketedData = bucketChartData(
      chartDataItems,
      bucketValuesBy,
      dataField,
      groupByField,
      groupByOptions,
    )

    // Update to Bucketed Data
    chartDataItems = bucketedData
  }

  // Step 5:
  // Sort if required - currently only for Bar Charts
  if (chartType === CardType.CHART_BAR && sortSettings) {
    // Do some sorting
    chartDataItems = sortChartData(
      chartDataItems,
      sortSettings.sortBy,
      sortSettings.sortOrder,
      dataField,
      groupByField,
    )
  }

  return { chartDataItems, groupByOptions }
}
