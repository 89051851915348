import type { Dispatch, FC, SetStateAction } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import * as IconSet from '@heroicons/react/24/outline'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { Tooltip } from '@mui/material'
import { ColDef, GetRowIdParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import 'src/styles/recordGrid.css'

import FieldRenderer from 'src/components/HubDash/FieldRenderer'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'

import type { HubDashCardType } from '../lib/types'
interface RecordListProps {
  cardSocket: any // not typed yet
  card: HubDashCardType
  isColorFiltered: boolean
  isAction: boolean
  setSelectedRecord: Dispatch<SetStateAction<any>> // not typed yet
  selectedColor: string
  recordSearchVal: string
}

const recordGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

const RecordGrid: FC<RecordListProps> = ({
  cardSocket,
  card,
  isColorFiltered,
  isAction,
  setSelectedRecord,
  selectedColor,
  recordSearchVal,
}) => {
  let records = cardSocket.records

  if (isColorFiltered) {
    records = isAction ? cardSocket.activeRecords : cardSocket.inactiveRecords
  }

  const getRowId = useCallback(
    (params: GetRowIdParams) => String(params.data.id),
    [],
  )

  const valueGetter = (field, data) => {
    const cellValue = data.getCellValue(field.name)
    if (typeof cellValue !== 'string') {
      return cellValue
    }
    const datePattern = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}Z)?$/
    if (cellValue.match(datePattern)) {
      return new Date(cellValue)
    }
    return data.getCellValueAsString(field.name)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
    }
  }, [])

  const [colDefs] = useState<ColDef[]>(() => {
    const columns = cardSocket.table.fields
      .filter((field) => !field.viewHidden && field?.formula_type !== 'button')
      .map((field) => {
        if (field.primary) {
          return {
            headerName: field.name,
            field: field.name,
            pinned: 'left',
            lockPosition: 'left',
            width: '400%',
            valueGetter: ({ data }) => {
              return valueGetter(field, data)
            },
            cellRenderer: ({ data }) => {
              return (
                <div className="" data-testid="record-list-row">
                  <FieldRenderer field={field} record={data} isName={false} />
                  {data.decorators?.left_border_color.length > 0 &&
                    isAction && (
                      <div className="flex items-center gap-2">
                        <>
                          {data.decorators.left_border_color.map((color) => {
                            const colorIconName = card.cardColors.find(
                              (c) => c.colorName === color.color,
                            )
                            const ColorIcon = colorIconName
                              ? IconSet[colorIconName.icon]
                              : null
                            return (
                              <div
                                className="grid h-6 w-6 place-items-center rounded-md"
                                key={color.id}
                                style={{
                                  backgroundColor: getHexForColor(color.color),
                                }}
                              >
                                {ColorIcon && (
                                  <ColorIcon className="h-5 w-5 text-white" />
                                )}
                              </div>
                            )
                          })}
                        </>
                      </div>
                    )}
                </div>
              )
            },
          }
        }
        return {
          headerName: field.name,
          field: field.name,
          valueGetter: ({ data }) => {
            return valueGetter(field, data)
          },
          cellRenderer: ({ data }) => {
            return (
              <div className="flex h-full w-full items-center">
                <FieldRenderer field={field} record={data} isName={false} />
              </div>
            )
          },
        }
      })

    columns.unshift({
      headerName: '',
      headerComponent: () => (
        <Tooltip title={'Number of comments'}>
          <ChatBubbleIcon style={{ fontSize: 14, color: '#bfbfbf' }} />
        </Tooltip>
      ),
      field: 'commentCount',
      pinned: 'left',
      width: 40,
      sortable: false,
      resizable: false,
      lockPosition: 'left',
      cellRenderer: ({ data }) => {
        return (
          <div className="flex h-full w-full items-center justify-center">
            <p
              className="text-indigo-500"
              data-testid={`hubdash-record-count-${data.commentCount}`}
            >
              {data.commentCount === 0 ? '' : data.commentCount}
            </p>
          </div>
        )
      },
    })
    return columns
  })

  const filterByColor = (record) => {
    if (!isColorFiltered) {
      return true
    }
    if (
      isAction &&
      selectedColor !== null &&
      selectedColor !== '' &&
      selectedColor !== 'white'
    ) {
      if (record?.decorators?.left_border_color.length > 0) {
        if (
          record.decorators.left_border_color.some(
            (color) => color.color === selectedColor,
          )
        ) {
          return true
        }
      }
    } else {
      return true
    }
    return false
  }

  const filteredRecords = records.filter((record) => {
    return filterByColor(record)
  })

  return (
    <div className="hide-scrollbar ag-theme-quartz flex h-full flex-col gap-2 overflow-scroll pb-4">
      <AgGridReact
        rowData={filteredRecords}
        quickFilterText={recordSearchVal}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        onRowClicked={({ data }) => {
          setSelectedRecord(records.find((record) => record.id === data.id))
        }}
        getRowId={getRowId}
        rowHeight={80}
        theme={recordGridTheme}
        rowStyle={{ cursor: 'pointer' }}
      />
    </div>
  )
}

export default RecordGrid
