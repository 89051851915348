import { type Dispatch, type FC, type SetStateAction } from 'react'

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { IconoirProvider } from 'iconoir-react'

import { BaserowFieldType, BaserowFormulaType } from '../lib/enums'
import type { AnyBaserowField, BaserowReference } from '../lib/types'
import { getIconForField } from '../RecordExpand/RecordExpand'

export enum SummaryType {
  EMPTY = 'empty',
  FILLED = 'filled',
  PERCENT_EMPTY = 'percent-empty',
  PERCENT_FILLED = 'percent-filled',
  EARLIEST_DATE = 'earliest-date',
  LATEST_DATE = 'latest-date',
  MEDIAN = 'median',
  MIN = 'min',
  MAX = 'max',
  SUM = 'sum',
  AVERAGE = 'average',
}

const summaryNiceName = (type: SummaryType): string => {
  switch (type) {
    case SummaryType.EMPTY:
      return 'Empty'
    case SummaryType.FILLED:
      return 'Filled'
    case SummaryType.PERCENT_EMPTY:
      return 'Percent Empty'
    case SummaryType.PERCENT_FILLED:
      return 'Percent Filled'
    case SummaryType.EARLIEST_DATE:
      return 'Earliest Date'
    case SummaryType.LATEST_DATE:
      return 'Latest Date'
    case SummaryType.MIN:
      return 'Min'
    case SummaryType.MAX:
      return 'Max'
    case SummaryType.MEDIAN:
      return 'Median'
    case SummaryType.SUM:
      return 'Sum'
    case SummaryType.AVERAGE:
      return 'Average'
    default:
      return ''
  }
}

interface FieldSummarySettingsProps {
  fieldsList: AnyBaserowField[]
  loadingViews: boolean
  selectedTable: any // not typed yet
  summaryField: BaserowReference
  setSummaryField: Dispatch<SetStateAction<BaserowReference>>
  summaryType: SummaryType
  setSummaryType: Dispatch<SetStateAction<SummaryType>>
}

const FieldSummarySettings: FC<FieldSummarySettingsProps> = ({
  fieldsList,
  loadingViews,
  selectedTable,
  summaryField,
  setSummaryField,
  summaryType,
  setSummaryType,
}) => {
  const numberTypeFields = [
    BaserowFieldType.NUMBER,
    BaserowFieldType.AUTONUMBER,
    BaserowFieldType.COUNT,
    BaserowFieldType.DURATION,
  ]
  const dateTypeFields = [
    BaserowFieldType.LAST_MODIFIED,
    BaserowFieldType.DATE,
    BaserowFieldType.CREATED_ON,
  ]

  // Match the type with what was found in field list
  const matchedField =
    fieldsList?.find((field) => field?.id === summaryField?.id) ?? null
  const matchedFieldType = matchedField?.type ?? BaserowFieldType.TEXT

  const showNumberTypes = numberTypeFields.includes(matchedFieldType)
  const showDateTypes = dateTypeFields.includes(matchedFieldType)

  const formulaNumberFields = [
    BaserowFormulaType.NUMBER,
    BaserowFormulaType.DURATION,
  ]
  const formulaDateFields = [
    BaserowFormulaType.DATE,
    BaserowFormulaType.DATE_INTERVAL,
  ]

  const showNumberTypesForFormulaResult =
    formulaNumberFields.includes(matchedField?.formula_type) ||
    formulaNumberFields.includes(matchedField?.array_formula_type)

  const showDateTypesForFormulaResult =
    formulaDateFields.includes(matchedField?.formula_type) ||
    formulaDateFields.includes(matchedField?.array_formula_type)

  const summaryOptions = [
    SummaryType.EMPTY,
    SummaryType.FILLED,
    SummaryType.PERCENT_EMPTY,
    SummaryType.PERCENT_FILLED,
  ]

  if (showDateTypes || showDateTypesForFormulaResult) {
    summaryOptions.push(SummaryType.EARLIEST_DATE)
    summaryOptions.push(SummaryType.LATEST_DATE)
  }

  if (showNumberTypes || showNumberTypesForFormulaResult) {
    summaryOptions.push(SummaryType.AVERAGE)
    summaryOptions.push(SummaryType.SUM)
    summaryOptions.push(SummaryType.MIN)
    summaryOptions.push(SummaryType.MAX)
    summaryOptions.push(SummaryType.MEDIAN)
  }

  const savedSummaryNotFound =
    summaryType && !summaryOptions.includes(summaryType)

  return (
    <div className="flex flex-col gap-4 pt-4">
      <div className="flex flex-col gap-4">
        <Autocomplete
          options={fieldsList}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Field" />}
          disableClearable
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id
          }}
          value={summaryField}
          onChange={(_, newValue) => {
            setSummaryField({
              id: newValue?.id,
              name: newValue?.name,
            })
            // Reset Val on Change
            setSummaryType(null)
          }}
          loading={loadingViews}
          disabled={!selectedTable}
          renderOption={(props, option) => (
            <li {...props}>
              <div className="flex items-center justify-between gap-4">
                <IconoirProvider
                  iconProps={{
                    color: '#AAAAAA',
                    strokeWidth: 2,
                    width: '1em',
                    height: '1em',
                  }}
                >
                  {getIconForField({ type: option?.type })}
                </IconoirProvider>

                <p>{option?.name}</p>
              </div>
            </li>
          )}
        />
      </div>
      <div className="flex flex-col gap-4 capitalize">
        <FormControl>
          <InputLabel
            id="summary-type"
            disabled={!summaryField}
            error={summaryField && (!summaryType || savedSummaryNotFound)}
          >
            Summary Type
          </InputLabel>
          <Select
            error={!summaryType || savedSummaryNotFound}
            labelId="summary-type"
            label="Summary Type"
            value={summaryType}
            disabled={!summaryField}
            onChange={(event) => {
              setSummaryType(event.target.value as SummaryType)
            }}
          >
            {summaryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {summaryNiceName(option)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText
            error={summaryField && (!summaryType || savedSummaryNotFound)}
          >
            {summaryField && (
              <>
                {!summaryType && 'Select a Summary Type.'}
                {savedSummaryNotFound && 'Saved value not found in options.'}
              </>
            )}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

export default FieldSummarySettings
