import dayjs, { type Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { BucketByValues } from '../../lib/enums'
import type { AnyBaserowField } from '../../lib/types'

import type { ChartItem, GroupOption } from './getChartData'
import { getFieldDisplayValues } from './getFieldDisplayValues'

dayjs.extend(customParseFormat)

const convertStringToDayJsWithFieldFormat = (
  dateString: string,
  baserowField: AnyBaserowField,
): Dayjs => {
  const dateFormat =
    baserowField?.date_format === 'EU' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  const timeFormat = baserowField?.date_include_time
    ? baserowField?.date_time_format === '24'
      ? 'HH:mm'
      : 'hh:mma'
    : ''

  const formatString = `${dateFormat}${timeFormat ? ' ' + timeFormat : ''}`

  return dayjs(dateString, formatString)
}

const getBucketByString = (
  date: Dayjs,
  bucketByValue: BucketByValues,
): string => {
  switch (bucketByValue) {
    case BucketByValues.DAY:
      return `${String(date?.format('DD/MM/YYYY'))}`
    case BucketByValues.WEEK:
      return `${String(date?.startOf('week').format('DD MMM'))} - ${String(date.endOf('week').format('DD MMM'))}, ${String(date?.year())}`
    case BucketByValues.MONTH:
      return `${String(date?.format('MMMM'))}`
    case BucketByValues.QUARTER:
      return `Q${String(date?.quarter())}`
    case BucketByValues.YEAR:
      return `${String(date?.year())}`
    case BucketByValues.DAY_OF_WEEK:
      return `${String(date?.format('dddd'))}`
    case BucketByValues.MONTH_OF_YEAR:
      return `${String(date?.format('MMMM'))}, ${String(date?.year())}`
    case BucketByValues.QUARTER_OF_YEAR:
      return `Q${String(date?.quarter())}, ${String(date?.year())}`
    default:
      return 'Empty'
  }
}

export const bucketChartData = (
  chartItems: ChartItem[],
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
  groupByField: AnyBaserowField,
  groupByOptions: GroupOption[],
): ChartItem[] => {
  const bucketedValues: ChartItem[] = []

  for (const bar of chartItems) {
    // Exit early for the Empty Bar
    // Empty Bar will already have correct count and be filtered out if includeEmptyResults is false
    if (bar?.name === 'Empty') {
      const matchedEmpty = bucketedValues?.find(
        (barOption) => barOption?.name === 'Empty',
      )

      if (!matchedEmpty) {
        bucketedValues.push(bar)
      } else {
        matchedEmpty.value += bar?.value
      }
    }

    // Convert to DayJS
    const dateValue = convertStringToDayJsWithFieldFormat(bar?.name, dataField)

    // Check for valid date
    if (dateValue.isValid()) {
      // Get Bar Bucket String
      const dateOption = getBucketByString(dateValue, bucketByValue)

      // Check for matching
      const matchingBar = bucketedValues?.find(
        (barOption) => barOption?.name === dateOption,
      )

      // Push Bar or Update count
      if (!matchingBar) {
        // Push cleaned val without grouped

        const initialBar = {
          name: dateOption,
          color: bar.color,
          hex: bar.hex,
          value: bar.value,
          records: bar.records,
        }

        bucketedValues.push(initialBar)
      } else {
        matchingBar.value += bar.value
        matchingBar.records = [...matchingBar.records, ...bar.records]
      }
    }
  }

  // If groupBy, loop through bucketedValues and apply custom key
  if (groupByField) {
    for (const bucketValue of bucketedValues) {
      let recordValues = []
      for (const record of bucketValue.records) {
        recordValues = [
          ...recordValues,
          ...getFieldDisplayValues(
            record?.getCellValue(groupByField?.name),
            groupByField,
          ),
        ]
      }

      for (const groupByValue of groupByOptions) {
        bucketValue[groupByValue?.name] = recordValues?.filter((value) => {
          if (value === '' && groupByValue?.name === 'Empty') {
            return true
          } else {
            return value === groupByValue.name
          }
        })?.length
      }
    }
  }

  return bucketedValues
}
