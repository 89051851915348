import type { Dispatch, FC, SetStateAction } from 'react'

import ColorModuleCell from 'src/components/HubDash/CardExpand/InfoPanel/ColorModuleCell'

import type { HubDashCardType } from '../../lib/types'

interface InfoPanelProps {
  card: HubDashCardType
  selectedColor: string
  setSelectedColor: Dispatch<SetStateAction<string>>
  view: any // not typed yet
  records: any[] // not typed yet
}

const InfoPanel: FC<InfoPanelProps> = ({
  card,
  selectedColor,
  setSelectedColor,
  view,
  records,
}) => {
  return (
    <div className="hide-scrollbar flex flex-col overflow-scroll">
      <ColorModuleCell
        hubDashCardId={card.id}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        view={view}
        records={records}
        card={card}
      />
    </div>
  )
}

export default InfoPanel
