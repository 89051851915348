import dayjs from 'dayjs'

import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import type { BaserowFormulaField } from '../../lib/baserow/baserowFieldTypes'
import { BaserowFormulaType } from '../../lib/enums'

export const getFormulaFieldDisplayValues = (
  currentValue: any, // not typed yet
  formulaType: string,
  displayField: BaserowFormulaField,
): string[] => {
  switch (formulaType) {
    case BaserowFormulaType.ARRAY: {
      return currentValue
        ? currentValue.flatMap((value) =>
            getFormulaFieldDisplayValues(
              value,
              displayField?.array_formula_type,
              displayField,
            ),
          )
        : ['']
    }
    case BaserowFormulaType.BOOLEAN: {
      return [
        String(currentValue).charAt(0).toUpperCase() +
          String(currentValue).slice(1),
      ]
    }
    case BaserowFormulaType.BUTTON: {
      return [currentValue?.url ?? '']
    }
    case BaserowFormulaType.DATE:
    case BaserowFormulaType.DATE_INTERVAL: {
      if (!currentValue) {
        return ['']
      } else if (dayjs(currentValue).isValid()) {
        return [
          dayjs(currentValue).format(
            `DD/MM/YYYY${displayField?.date_include_time ? (displayField?.date_time_format === '24' ? ' HH:mm' : ' hh:mma') : ''}`,
          ),
        ]
      } else if (dayjs(currentValue?.value).isValid()) {
        return [
          dayjs(currentValue?.value).format(
            `DD/MM/YYYY${displayField?.date_include_time ? (displayField?.date_time_format === '24' ? ' HH:mm' : ' hh:mma') : ''}`,
          ),
        ]
      } else {
        return ['Blank Field Value']
      }
    }
    case BaserowFormulaType.DURATION: {
      return [formatDurationValue(currentValue, displayField.duration_format)]
    }
    case BaserowFormulaType.SINGLE_FILE: {
      return [currentValue?.visible_name ?? '']
    }
    case BaserowFormulaType.SINGLE_SELECT: {
      return [currentValue?.value ?? '']
    }
    case BaserowFormulaType.MULTIPLE_SELECT: {
      return currentValue?.map((option) => {
        return option?.value ?? ''
      })
    }
    case BaserowFormulaType.LINK: {
      return [currentValue?.url ?? '']
    }
    case BaserowFormulaType.NUMBER:
    case BaserowFormulaType.CHAR:
    case BaserowFormulaType.TEXT:
    case BaserowFormulaType.URL: {
      return [currentValue ?? '']
    }
    case BaserowFormulaType.INVALID:
    default:
      return ['']
  }
}
