import type { Dispatch, FC, SetStateAction } from 'react'

import { PencilSquareIcon } from '@heroicons/react/20/solid'

import useHubDashStore from 'src/lib/stores/hubDashStore'

import type { HubDashCardType } from '../lib/types'

interface HeadingCardProps {
  card: HubDashCardType
  setSettingsCard: Dispatch<SetStateAction<HubDashCardType>> // not typed yet
  setCardSettingsOpen: Dispatch<SetStateAction<boolean>>
  isPreview: boolean
}

const HeadingCard: FC<HeadingCardProps> = ({
  card,
  setSettingsCard,
  setCardSettingsOpen,
  isPreview,
}) => {
  const isUnlocked = useHubDashStore((state) => state.isUnlocked)

  return (
    <div
      className={`px-2 last:flex flex items-center justify-between h-full ${
        isUnlocked && !isPreview
          ? 'border-2 border-gray-200 rounded-lg'
          : 'rounded-lg'
      }`}
      style={{
        backgroundColor: card?.cardSettings?.appearance?.backgroundColor?.hex,
      }}
    >
      <p
        className="text-gray-500 text-xl truncate"
        style={{
          color: card?.cardSettings?.appearance?.textColor?.hex,
        }}
      >
        {card.name}
      </p>
      {isUnlocked && !isPreview && (
        <button
          className="hover:bg-gray-200 p-1 rounded-lg"
          onClick={() => {
            setSettingsCard(card)
            setCardSettingsOpen(true)
          }}
          onMouseDown={() => {
            setSettingsCard(card)
            setCardSettingsOpen(true)
          }}
        >
          <PencilSquareIcon className="h-5 w-5 text-gray-500" />
        </button>
      )}
    </div>
  )
}

export default HeadingCard
