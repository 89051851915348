import { Fragment, type FC } from 'react'

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import tinycolor from 'tinycolor2'

import { PIE_CHART_LIMIT } from '../lib/constants'
import { CardType } from '../lib/enums'
import type { HubDashCardSettings, HubDashCardType } from '../lib/types'

import { getChartData } from './ChartFunctions/getChartData'
import ChartLegend from './ChartLegend'
import ChartTooltip from './ChartTooltip'

interface CardContentChartPieProps {
  cardSocket: any // not typed yet
  card: HubDashCardType
}

const CardContentChartPie: FC<CardContentChartPieProps> = ({
  cardSocket,
  card,
}) => {
  const cardSettings = card?.cardSettings as HubDashCardSettings
  const dataField = cardSettings?.chartSettings?.category

  // Legend.visible can be undefined, true, false
  const legendVisible = cardSettings?.chartSettings?.legend?.visible !== false
  const legendPosition = cardSettings?.chartSettings?.legend?.position
  const horizontalLegend = ['top', 'bottom'].includes(legendPosition)

  const legendAlignHorizontal =
    legendPosition === 'left' || legendPosition === 'right'
      ? legendPosition
      : 'right'
  const legendAlignVertical =
    legendPosition === 'top' || legendPosition === 'bottom'
      ? legendPosition
      : 'top'

  const tableFields = cardSocket.table.fields
  const tableDataField = tableFields.find(
    (field) => field.name === dataField?.name,
  )

  if (!tableDataField) {
    return null
  }

  const countRecords = true
  const includeEmptyRecords = true

  const { chartDataItems } = getChartData(
    tableDataField,
    cardSocket?.records,
    CardType.CHART_PIE,
    countRecords,
    includeEmptyRecords,
  )

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    count,
    name,
    fill,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    if (percent <= 1.1) return null
    return (
      <Fragment key={name}>
        <text
          x={x}
          y={y}
          fill={tinycolor.mostReadable(fill, ['#000000', '#ffffff'])}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`${name}`}
        </text>
        <text
          x={x}
          y={y + 15}
          fill={tinycolor.mostReadable(fill, ['#000000', '#ffffff'])}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={14}
        >
          {`${count} : ${(percent * 100).toFixed(0)}%`}
        </text>
      </Fragment>
    )
  }

  if (cardSocket.records.length === 0 || chartDataItems?.length === 0) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="text-gray-500">No data available</p>
      </div>
    )
  }

  if (chartDataItems?.length > PIE_CHART_LIMIT) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p className="text-gray-500">There are too many records to display.</p>
      </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="99%" className="p-2">
      <PieChart>
        <Pie
          data={chartDataItems}
          dataKey="value"
          nameKey="label"
          fill="#000000"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {chartDataItems.map((entry, index) => (
            <Cell fill={entry.hex} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip content={<ChartTooltip />} />
        {legendVisible && (
          <Legend
            content={
              <ChartLegend
                data={chartDataItems}
                isHorizontal={horizontalLegend}
                textColor={cardSettings.appearance.textColor.hex ?? '#737373'}
              />
            }
            layout={horizontalLegend ? 'horizontal' : 'vertical'}
            align={legendAlignHorizontal}
            verticalAlign={legendAlignVertical}
            wrapperStyle={{
              padding: '1rem',
              paddingTop: legendAlignVertical === 'top' ? '0rem' : '1rem',
              paddingBottom: legendAlignVertical === 'top' ? '1rem' : '0rem',
              height: horizontalLegend ? '30%' : '100%',
            }}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  )
}

export default CardContentChartPie
