import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import HeadingCard from 'src/components/HubDash/HubDashCard/HeadingCard'
import HubDashCard from 'src/components/HubDash/HubDashCard/HubDashCard'
import loadBaserowData from 'src/components/HubDash/lib/baserow/loadBaserowData'
import useHubDashStore from 'src/lib/stores/hubDashStore'

import { CardType } from '../lib/enums'
import type { HubDashCardType } from '../lib/types'

interface CardPreviewProps {
  card: HubDashCardType
  setSettingsCard: Dispatch<SetStateAction<HubDashCardType>>
  setCardSettingsOpen: Dispatch<SetStateAction<boolean>>
}

export const CardPreview: FC<CardPreviewProps> = ({
  card,
  setSettingsCard,
  setCardSettingsOpen,
}) => {
  let cardComponent = null

  const [baserowData, setBaserowData] = useState({})
  const [token] = useHubDashStore((state) => [state.token])

  switch (card.type) {
    case CardType.HEADING:
      cardComponent = (
        <HeadingCard
          card={card}
          setSettingsCard={setSettingsCard}
          setCardSettingsOpen={setCardSettingsOpen}
          isPreview={true}
        />
      )
      break
    default:
      cardComponent = (
        <HubDashCard
          card={card}
          setSettingsCard={setSettingsCard}
          setCardSettingsOpen={setCardSettingsOpen}
          isPreview={true}
          baserowData={baserowData}
        />
      )
      break
  }

  useEffect(() => {
    const loadCardData = async () => {
      const data = await loadBaserowData({
        workspaceId: card?.cardData?.workspace?.id,
        baseId: card?.cardData?.base?.id,
        tableId: card?.cardData?.table?.id,
        viewId: card?.cardData?.view?.id,
        tableName: card?.cardData?.table?.name,
        viewName: card?.cardData?.view?.name,
        token: token,
        config: card,
      })
      setBaserowData(data)
    }
    if (card?.type === CardType.HEADING) {
      setBaserowData({})
    } else {
      loadCardData()
    }
  }, [card, token])

  return (
    <div className="mt-96 flex h-full w-full flex-col items-center gap-2">
      <div
        className={`flex items-center justify-between ${
          card?.type === CardType.HEADING ? 'w-1/2' : 'w-1/2'
        }`}
      >
        <div>
          <p className="text-xl text-white">Preview</p>
        </div>
        {/*card?.type !== 'heading' && (
          <div>
            <Button>Expand</Button>
          </div>
        )*/}
      </div>
      <div
        className={`${
          card?.type === CardType.HEADING
            ? 'w-1/2 rounded-lg bg-white'
            : 'h-1/2 w-1/2'
        }`}
      >
        {cardComponent}
      </div>
    </div>
  )
}

export default CardPreview
