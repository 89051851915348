import { useEffect, type Dispatch, type FC, type SetStateAction } from 'react'

import { FormControl, FormHelperText, TextField } from '@mui/material'

import Accordion from 'src/components/Library/Accordion/Accordion'
import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import useAnalytics from 'src/lib/hooks/useAnalytics'

import type { BaserowReference } from '../../lib/types'
import type { CardFormErrors } from '../CardSettingsDrawer'

interface CardSettingsSourceProps {
  basesList: any[] // not typed yet
  selectedBase: BaserowReference
  setSelectedBase: Dispatch<SetStateAction<BaserowReference>>
  tablesList: any[] // not typed yet
  selectedTable: BaserowReference
  setSelectedTable: Dispatch<SetStateAction<BaserowReference>>
  viewsList: any[] // not typed yet
  selectedView: BaserowReference
  setSelectedView: Dispatch<SetStateAction<BaserowReference>>
  loadingViews: boolean
  loadingBases: boolean
  formErrors: CardFormErrors
  setFormErrors: Dispatch<SetStateAction<CardFormErrors>>
}

export const CardSettingsSource: FC<CardSettingsSourceProps> = ({
  basesList,
  selectedBase,
  setSelectedBase,
  tablesList,
  selectedTable,
  setSelectedTable,
  viewsList,
  selectedView,
  setSelectedView,
  loadingViews,
  loadingBases,
  formErrors,
  setFormErrors,
}) => {
  const { trackEvent } = useAnalytics()
  return (
    <Accordion title={'Source'} expanded>
      <div className="flex flex-col gap-3 pt-1">
        <FormControl fullWidth error={formErrors.base ? true : false}>
          <AutoComplete
            options={basesList}
            getOptionLabel={(option) => option?.name}
            groupBy={(option) => option.workspace.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Base"
                required
                error={formErrors.base ? true : false}
              />
            )}
            onChange={(_, newValue) => {
              if (newValue) {
                setFormErrors({ ...formErrors, base: '' })
              }
              setSelectedBase(newValue)
              trackEvent('HubDash', 'Card Builder Select Base')
            }}
            value={selectedBase}
            loading={loadingBases}
            data-testid={`hubdash-base-select-loading-${loadingBases}`}
          />

          <FormHelperText>{formErrors.base}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={selectedBase && formErrors.table ? true : false}
        >
          <AutoComplete
            options={tablesList}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="Table"
                error={selectedBase && formErrors.table ? true : false}
              />
            )}
            onChange={(_, newValue) => {
              if (newValue) {
                setFormErrors({ ...formErrors, table: '' })
              }
              setSelectedTable(newValue)
              trackEvent('HubDash', 'Card Builder Select Table')
            }}
            value={selectedTable}
            disabled={!selectedBase}
            loading={loadingBases}
            data-testid={`hubdash-table-select-loading-${loadingBases}`}
          />
          <FormHelperText>{selectedBase && formErrors.table}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={selectedTable && formErrors.view ? true : false}
        >
          <AutoComplete
            options={viewsList}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label="View"
                error={selectedTable && formErrors.view ? true : false}
              />
            )}
            onChange={(_, newValue) => {
              if (newValue) {
                setFormErrors({ ...formErrors, view: '' })
              }
              setSelectedView(newValue)
              trackEvent('HubDash', 'Card Builder Select View')
            }}
            value={selectedView}
            disabled={!selectedTable}
            loading={loadingViews}
            data-testid={`hubdash-view-select-loading-${loadingViews}`}
          />
          <FormHelperText>{selectedTable && formErrors.view}</FormHelperText>
        </FormControl>
      </div>
    </Accordion>
  )
}

export default CardSettingsSource
