import { useEffect, useState } from 'react'

import {
  ArrowTrendingUpIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChartPieIcon,
  LanguageIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import { TextField } from '@mui/material'

import { useMutation } from '@redwoodjs/web'

import { QUERY as LibraryQuery } from 'src/components/HubDash/CardLibrary/CardLibraryCell/CardLibraryCell'
import { QUERY as LayoutQuery } from 'src/components/HubDash/HubDashLayoutListCell/HubDashLayoutListCell'
import AutoComplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import useHubDashStore from 'src/lib/stores/hubDashStore'

export const CardLibraryList = ({ cardList }) => {
  const [layoutData] = useHubDashStore((state) => [state.layoutData])

  const cardIdsInLayout = layoutData?.HubDashLayoutToHubDashCard.map(
    (layoutToCard) => layoutToCard.hubDashCard.id,
  )

  const [searchValue, setSearchValue] = useState('')
  const [workspaceOptions, setWorkspaceOptions] = useState([])
  const [baseOptions, setBaseOptions] = useState([])
  const [tableOptions, setTableOptions] = useState([])
  const [viewOptions, setViewOptions] = useState([])

  const [selectedWorkspaces, setSelectedWorkspaces] = useState([])
  const [selectedBases, setSelectedBases] = useState([])
  const [selectedTables, setSelectedTables] = useState([])
  const [selectedViews, setSelectedViews] = useState([])

  useEffect(() => {
    const workspaceList = cardList.map(
      (card) => card?.cardData?.workspace?.name,
    )
    const baseList = cardList.map((card) => card?.cardData?.base?.name)
    const tableList = cardList.map((card) => card?.cardData?.table?.name)
    const viewList = cardList.map((card) => card?.cardData?.view?.name)

    setSearchValue('')
    setWorkspaceOptions([...new Set(workspaceList)])
    setBaseOptions([...new Set(baseList)])
    setTableOptions([...new Set(tableList)])
    setViewOptions([...new Set(viewList)])
    setSelectedWorkspaces([])
    setSelectedBases([])
    setSelectedTables([])
    setSelectedViews([])
  }, [cardList])

  const fixCardType = (type) => {
    switch (type) {
      case 'counter':
        return 'Counter'
      case 'chartPie':
        return 'Pie Chart'
      case 'chartBar':
        return 'Bar Chart'
      case 'chartLine':
        return 'Line Chart'
      case 'heading':
        return 'Heading'
      default:
        return 'Unknown'
    }
  }

  const fixCardSettings = (type, cardSettings) => {
    switch (type) {
      case 'counter':
        return cardSettings?.filters?.counter?.count
      default:
        return null
    }
  }

  const assignCardIcon = (type) => {
    switch (type) {
      case 'counter':
        return <CalculatorIcon />
      case 'chartPie':
        return <ChartPieIcon />
      case 'chartBar':
        return <ChartBarIcon />
      case 'chartLine':
        return <ArrowTrendingUpIcon />
      case 'heading':
        return <LanguageIcon />
      default:
        return <QuestionMarkCircleIcon />
    }
  }

  const enrichedCardList = cardList.map((card) => ({
    id: card.id,
    name: card.name,
    icon: assignCardIcon(card.type),
    type: fixCardType(card.type),
    source: card.source,
    cardSettings: fixCardSettings(card.type, card.cardSettings),
    cardData: card.cardData,
    originalCard: card,
  }))

  const filteredCardList = enrichedCardList.filter(
    (card) =>
      card.name.toLowerCase().includes(searchValue.toLowerCase()) &&
      (selectedWorkspaces.length === 0 ||
        selectedWorkspaces.includes(card.cardData.workspace.name)) &&
      (selectedBases.length === 0 ||
        selectedBases.includes(card.cardData.base.name)) &&
      (selectedTables.length === 0 ||
        selectedTables.includes(card.cardData.table.name)) &&
      (selectedViews.length === 0 ||
        selectedViews.includes(card.cardData.view.name)) &&
      card.type !== 'Heading',
  )

  const CREATE_HUBDASH_LAYOUT_TO_HUBDASH_CARD = gql`
    mutation CreateHubDashLayoutToHubDashCard(
      $input: CreateHubDashLayoutToHubDashCardInput!
    ) {
      createHubDashLayoutToHubDashCard(input: $input) {
        id
      }
    }
  `

  const [createLayoutToCard, { loading: createLayoutToCardLoading }] =
    useMutation(CREATE_HUBDASH_LAYOUT_TO_HUBDASH_CARD, {
      onCompleted: () => {},
      refetchQueries: [{ query: LibraryQuery }, { query: LayoutQuery }],
    })

  const DELETE_HUBDASH_CARD_AND_REMOVE_FROM_LAYOUTS = gql`
    mutation DeleteHubDashCardAndRemoveFromLayouts($id: Int!) {
      deleteHubDashCardAndRemoveFromLayouts(id: $id) {
        id
      }
    }
  `

  const [deleteCard, { loading: deleteCardLoading }] = useMutation(
    DELETE_HUBDASH_CARD_AND_REMOVE_FROM_LAYOUTS,
    {
      onCompleted: () => {},
      refetchQueries: [{ query: LibraryQuery }, { query: LayoutQuery }],
    },
  )

  const handleAdd = (card) => {
    const layoutToCardInput = {
      hubDashLayoutId: layoutData.id,
      hubDashCardId: card.id,
      position: {
        x: 0,
        y: 99,
        w: 1,
        h: 4,
      },
    }

    createLayoutToCard({ variables: { input: layoutToCardInput } })
  }

  const handleDelete = (card) => {
    deleteCard({ variables: { id: card.id } })
  }

  return (
    <div className="flex h-full flex-col gap-2 pt-4">
      <SearchField value={searchValue} onChange={setSearchValue} />
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <AutoComplete
            options={workspaceOptions}
            size="small"
            className="w-full"
            renderInput={(params) => (
              <TextField {...params} label="Workspaces" />
            )}
            limitTags={3}
            onChange={(_, newValue) => {
              setSelectedWorkspaces(newValue)
            }}
            value={selectedWorkspaces}
            disableCloseOnSelect
            multiple
          />
          <AutoComplete
            options={baseOptions}
            size="small"
            className="w-full"
            renderInput={(params) => <TextField {...params} label="Bases" />}
            limitTags={3}
            onChange={(_, newValue) => {
              setSelectedBases(newValue)
            }}
            value={selectedBases}
            disableCloseOnSelect
            multiple
          />
        </div>
        <div className="flex gap-2">
          <AutoComplete
            options={tableOptions}
            size="small"
            className="w-full"
            renderInput={(params) => <TextField {...params} label="Tables" />}
            limitTags={3}
            onChange={(_, newValue) => {
              setSelectedTables(newValue)
            }}
            value={selectedTables}
            disableCloseOnSelect
            multiple
          />
          <AutoComplete
            options={viewOptions}
            size="small"
            className="w-full"
            renderInput={(params) => <TextField {...params} label="Views" />}
            limitTags={3}
            onChange={(_, newValue) => {
              setSelectedViews(newValue)
            }}
            value={selectedViews}
            disableCloseOnSelect
            multiple
          />
        </div>
      </div>
      <div className="flex h-full flex-col gap-2 overflow-auto rounded-md bg-gray-100 p-2">
        {filteredCardList.map((card) => (
          <div
            className="flex flex-col gap-2 rounded-lg border-2 border-gray-300 bg-white p-2"
            key={card.id}
          >
            <div className="flex items-center gap-2">
              <div className="flex h-12 h-full w-12 justify-center text-gray-400">
                {card.icon}
              </div>
              <div className="flex flex-col">
                <p className="truncate">{card.name}</p>
                <p>{card.type}</p>
                <p className="capitalize">{card.cardSettings}</p>
                <p></p>
              </div>
              <div className="ml-auto flex w-36 flex-col items-end truncate text-sm text-gray-500">
                <p className="truncate">{card.cardData.workspace.name}</p>
                <p className="truncate">{card.cardData.base.name}</p>
                <p className="truncate">{card.cardData.table.name}</p>
                <p className="truncate">{card.cardData.view.name}</p>
              </div>
            </div>
            <div className="flex w-full justify-between">
              <div>
                <Button
                  className="bg-red-500 hover:bg-red-600"
                  onClick={() => handleDelete(card)}
                >
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    handleAdd(card)
                  }}
                  disabled={cardIdsInLayout.includes(card.id)}
                >
                  {cardIdsInLayout.includes(card.id) ? 'In Layout' : 'Add'}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default CardLibraryList
