import { captureEvent } from '@sentry/browser'
import axios, { type AxiosError, type AxiosResponse } from 'axios'

import { enrichRecords } from 'src/components/HubDash/lib/baserow/baserowApi'
import { getRecordDecorationsInView } from 'src/components/HubDash/lib/baserow/brViewFilter'

import type { HubDashCardType } from '../types'

interface LoadBaserowDataOptions {
  workspaceId: number
  baseId: number
  tableId: number
  viewId: number
  tableName: string
  viewName: string
  token: string
  config: HubDashCardType
}

export const loadBaserowData = async ({
  workspaceId,
  baseId,
  tableId: lookupTableId,
  viewId: lookupViewId,
  tableName: lookupTableName,
  viewName: lookupViewName,
  token,
  config,
}: LoadBaserowDataOptions) => {
  const brApi = axios.create({
    baseURL: `${process.env.BASEROW_URL}/api`,
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': 'application/json',
    },
  })

  let tableId = lookupTableId || null
  let viewId = lookupViewId || null

  const errors: {
    message: string
    type: string
    entity: string
  }[] = []

  const handleError = (err: AxiosError, entity: string) => {
    if (err.response) {
      if (err.response.status === 404) {
        if (entity === 'table') {
          const message = `The table ${lookupTableName} - ${tableId} was not found in the base.`
          errors.push({ message, type: 'tableMissing', entity })
        } else if (entity === 'view') {
          const message = `The view ${lookupViewName} - ${viewId} was not found on the table ${lookupTableName} - ${tableId}.`
          errors.push({ message, type: 'viewMissing', entity })
        }

        return { data: null }
      } else if (err.response.status === 403 || err.response.status === 400) {
        if (entity === 'table') {
          const message = `You do not have access to the table ${lookupTableName} - ${tableId}`
          errors.push({ message, type: 'tableForbidden', entity })
        } else if (entity === 'view') {
          const message = `You do not have access to the view ${lookupViewName} - ${viewId} on the table ${lookupTableName} - ${tableId}.`
          errors.push({ message, type: 'viewForbidden', entity })
        }
        return { data: null }
      }
    }

    throw err
  }

  if (!lookupTableId) {
    const tablesResponse = await brApi.get(
      `/database/tables/database/${baseId}/`,
    )

    const tablesData: {
      data_sync: unknown
      database_id: number
      id: number
      name: string
      order: number
    }[] = tablesResponse.data
    const foundTable = tablesData.find(
      (table) => table.name === lookupTableName,
    )
    tableId = foundTable?.id

    if (!tableId) {
      errors.push({
        message: `Table ${lookupTableName} not found in base ${baseId}`,
        type: 'tableMissing',
        entity: 'table',
      })
    }
  } else {
    tableId = lookupTableId
  }

  if (!lookupViewId && tableId) {
    const viewsResponse = await brApi.get(`/database/views/table/${tableId}/`)
    const viewsData: any[] = viewsResponse.data
    const foundView = viewsData.find((view) => view.name === lookupViewName)

    viewId = foundView?.id

    if (!viewId) {
      errors.push({
        message: `View ${lookupViewName} not found in table ${tableId}`,
        type: 'viewMissing',
        entity: 'view',
      })
    }
  }

  const getErrorResult = () => {
    return {
      records: [],
      table: {
        id: tableId,
        name: lookupTableName,
        primaryField: null,
        fields: [],
      },
      view: null,
      base: { id: baseId },
      config,
      errors,
    }
  }

  const captureSentryError = () => {
    captureEvent({
      extra: { tableId, baseId, lookupTableName, errors },
      message: errors[0]?.message,
      level: 'error',
      fingerprint: [`loadBaserowData-w${workspaceId}-b${baseId}`],
    })
  }

  if (errors.length > 0) {
    captureSentryError()
    return getErrorResult()
  }

  const fetchAllData = async <T,>(url: string, allResults: T[] = []) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
      })
      const rowMetadata = response.data.row_metadata
      response?.data?.results?.map((record) => {
        record.commentCount = rowMetadata[record.id]?.row_comment_count
        return record
      })

      allResults = allResults.concat(response.data.results)

      if (response.data.next) {
        const nextUrl = response.data.next.replace('http://', 'https://')
        return fetchAllData(nextUrl, allResults)
      } else {
        return allResults
      }
    } catch (error) {
      return [] as T[]
    }
  }

  const [tableFieldsResponse, viewFieldsResponse, viewResponse, allResults] =
    await Promise.all([
      brApi
        .get(`/database/fields/table/${tableId}/`)
        .catch((err: AxiosError) => handleError(err, 'table')),
      brApi
        .get(`/database/views/${viewId}/field-options/`)
        .catch((err: AxiosError) => handleError(err, 'view')),
      brApi
        .get(`/database/views/${viewId}/?include=filters,sortings,decorations`)
        .catch((err: AxiosError) => handleError(err, 'view')),
      fetchAllData(
        `${process.env.BASEROW_URL}/api/database/views/grid/${viewId}/?size=500&include=row_metadata`,
      ).catch((err: AxiosError) => handleError(err, 'table')),
    ])

  if (config?.cardSettings?.filters?.user && errors.length === 0) {
    const userFilterField = config.cardSettings.filters.user

    // Check if field can be found by Id or Name
    if (
      !tableFieldsResponse.data.find(
        (field) => field.id === userFilterField.id,
      ) &&
      !tableFieldsResponse.data.find(
        (field) => field?.name === userFilterField?.name,
      )
    ) {
      const message = `The user filter field ${userFilterField.name} - ${userFilterField.id} was not found in the table ${lookupTableName} - ${tableId}.`
      errors.push({ message, type: 'userFilterFieldMissing', entity: 'table' })
    }
  }

  if (errors.length > 0) {
    captureSentryError()
    return getErrorResult()
  }

  const tableFieldsDataObject = tableFieldsResponse.data
  const viewFieldsDataObject = viewFieldsResponse.data

  const sortedFields = tableFieldsDataObject
    .map((item) => {
      const viewItem = viewFieldsDataObject.field_options[item.id]
      if (viewItem) {
        return {
          ...item,
          viewOrder: viewItem.order,
          viewHidden: viewItem.hidden,
        }
      } else {
        return item
      }
    })
    .sort((a, b) => a.viewOrder - b.viewOrder)

  const viewDataObject = viewResponse.data

  const processRecords = (records, viewData, tableFieldsData, tableId) => {
    const enrichedRecords = enrichRecords({
      token,
      records,
      tableFieldsData,
      tableId,
      workspaceId,
    })
    const decoratedRecords = getRecordDecorationsInView(
      enrichedRecords,
      viewData,
      tableFieldsData,
    )

    return decoratedRecords
  }

  const decoratedRecords = await processRecords(
    allResults,
    viewDataObject,
    tableFieldsDataObject,
    tableId,
  )

  return {
    records: decoratedRecords,
    table: {
      id: tableId,
      name: lookupTableName,
      primaryField: sortedFields
        ? sortedFields.find((field) => field.primary)
        : null,
      fields: sortedFields,
    },
    view: viewDataObject,
    base: { id: baseId },
    config: config,
    errors: errors,
  }
}

export default loadBaserowData
